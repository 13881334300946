import { IconBellRing } from '@troon/icons';
import { DialogContent, Dialog, DialogTrigger } from '@troon/ui';
import { Match, Switch, createSignal } from 'solid-js';
import { useUser } from '../../../../../../providers/user';
import { AuthFlow } from '../../../../../../partials/auth/auth';
import { CreateTeeTimeAlert } from './create-tee-time-alert';
import { TeeTimeAlertPushNotifications } from './tee-time-push-notifications';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = Omit<ComponentProps<typeof CreateTeeTimeAlert>, 'onClose' | 'onShowAppDownload'>;

export function TeeTimeAlertPrompt(props: Props) {
	return (
		<div class="flex flex-row justify-stretch gap-4">
			<IconBellRing class="size-full min-w-8 max-w-24 shrink grow basis-1/12 text-brand" />
			<div class="flex flex-col items-start gap-4">
				<h3 class="text-lg font-semibold">Not seeing the tee time you want?</h3>
				<p>Get an alert if a tee time becomes available based on your preferences.</p>

				<TeeTimeAlertDialog {...props}>
					<DialogTrigger appearance="secondary">Set alerts</DialogTrigger>
				</TeeTimeAlertDialog>
			</div>
		</div>
	);
}

export function TeeTimeAlertDialog(props: ParentProps<Props>) {
	const [showAppDownload, setShowAppDownload] = createSignal(false);
	const user = useUser();
	const [open, setOpen] = createSignal(false);

	return (
		<Dialog key="tee-time-alert-upsell" open={open()} onOpenChange={setOpen}>
			{props.children}
			<DialogContent
				header={showAppDownload() ? 'Download the Troon Golf app!' : user() ? 'Set tee time alert' : undefined}
				headerLevel="h4"
				onClose={() => {
					setShowAppDownload(false);
				}}
				autoWidth={!user()}
				noPadding={!user()}
				noClose={!user()}
				floatingClose={!user()}
			>
				<Switch>
					<Match when={!user()}>
						<AuthFlow />
					</Match>
					<Match when={!showAppDownload()}>
						<CreateTeeTimeAlert
							onShowAppDownload={() => setShowAppDownload(true)}
							{...props}
							onClose={() => setOpen(false)}
						/>
					</Match>
					<Match when={showAppDownload()}>
						<TeeTimeAlertPushNotifications />
					</Match>
				</Switch>
			</DialogContent>
		</Dialog>
	);
}
